<template>
  <div class="news">
    <div class="banner">
      <img src="@/assets/news/banner.jpg" />
    </div>
    <div class="title">
      <div class="title-wrapper animate__animated animate__fadeInUp">
        <p class="cn">新闻动态</p>
        <p class="en">NEWS</p>
      </div>
    </div>
    <div class="content">
      <div class="content-wrapper animate__animated animate__fadeInUp">
        <div class="content-body">
          <div class="card" v-for="(item, i) in newsList" :key="i" @click="openNews(item.url)">
            <img class="card-img" :src="item.image" />
            <div class="card-title">
              {{ item.title }}
            </div>
            <div class="card-content">
              {{ item.brief }}
            </div>
          </div>
        </div>
        <div class="content-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="size"
            :current-page="page"
            :total="total"
            @current-change="currentChangeHandle"
            >
          </el-pagination>
        </div>
      </div>
    </div>
    <Toast ref="myToast"></Toast>
  </div>
</template>

<script>
import request from '@/api/http'
import { getNews } from '@/api/urls'
import Toast from '@/components/Toast'

export default {
  name: 'News',
  metaInfo: {
    title: '智慧城管|垃圾治理|垃圾分类|智慧环卫|智慧公厕|智能AI分析-海多普-新闻动态',
    meta: [
      {
        name: 'keyWords',
        content: '海多普,新闻动态,智慧城管,垃圾治理,垃圾分类,智慧环卫,智慧公厕,智能AI分析'
      },
      {
        name: 'description',
        content: '海多普，城市管理信息服务专家'
      }
    ]
  },
  data() {
    return {
      newsList: [],
      total: 0,
      size: 12,
      page: 1,
      pages: 0
    }
  },
  components: {
    Toast
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      let res = await request.get(getNews, {
        params: {
          page: this.page,
          limit: this.size
        }
      })
      if (!res) return
      if (res.code === 200) {
        this.newsList = res.data.list
        this.total = res.data.totalCount
        this.pages = res.data.totalPage
      } else {
        this.$refs.myToast.show(1, res.msg)
      }
    },
    currentChangeHandle(val) {
      this.page = val
      this.getData()
    },
    openNews(link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  width: 100%;
  background-color: #f7f7f7;
  overflow: hidden;
  .banner {
    width: 100%;
    overflow: hidden;
    margin-bottom: 60px;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  .animate__animated {
    opacity: 0;
  }
  .title {
    height: 60px;
    margin-bottom: 50px;
    .title-wrapper {
      width: 1200px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .cn {
        font-size: 30px;
      }
      .en {
        font-size: 16px;
        color: #99acbc;
        font-weight: 600;
      }
    }
  }
  .content {
    width: 100%;
    margin-bottom: 60px;
    .content-wrapper {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .content-body {
        display: flex;
        flex-wrap: wrap;
        min-height: 500px;
        .card {
          width: 280px;
          height: 370px;
          margin-bottom: 26px;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background-color: #ffffff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          }
          &:not(:nth-child(4n)) {
            margin-right: 26px;
          }
          .card-img {
            display: block;
            width: 280px;
            height: 210px;
            object-fit: cover;
            user-select: none;
          }
          .card-title {
            font-size: 20px;
            color: #333333;
            line-height: 24px;
            padding: 0 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 28px;
            user-select: none;
          }
          .card-content {
            font-size: 16px;
            padding: 0 10px;
            color: #666666;
            line-height: 27px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-top: 10px;
            user-select: none;
          }
        }
      }
      .content-page {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
